import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

import "./equipe.scss";

export const TeammateTemplate = ({
  content,
  contentComponent,
  description,
  name,
  role,
  doctolib,
  specialite,
  featuredimage,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <div className="TeammateTemplate">
      <div className="TeammateTemplate__header">
        <h1>{name}</h1>
        <h3>
          {role} {specialite}
        </h3>
      </div>
      <div className="TeammateTemplate__content">
        <div className="TeammateTemplate__misc">
          <PreviewCompatibleImage
            className="TeammateTemplate__picture"
            imageInfo={{
              image: featuredimage,
              alt: `photo de ${name}`,
            }}
          />
          <div>
            {doctolib && doctolib.length > 0 && (
              <a
                href={doctolib}
                className="TeammateTemplate__Appointment Button"
              >
                Prendre rendez-vous
              </a>
            )}
          </div>
        </div>
        <div className="TeammateTemplate__Resume">
          <p>{description}</p>
          <PostContent
            className="Markdown TeammateTemplate__ResumeContent"
            content={content}
          />
        </div>
      </div>
    </div>
  );
};

TeammateTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  name: PropTypes.string,
  role: PropTypes.string,
  specialite: PropTypes.string,
  doctolib: PropTypes.string,
  helmet: PropTypes.object,
};

const TeammatePost = ({ data }) => {
  const { markdownRemark: post } = data;
  const { frontmatter, html } = post || {};
  const { featuredimage, name, description, tags, role, doctolib, specialite } =
    frontmatter || {};
  return (
    <Layout>
      <header className="Layout__header">
        <Helmet titleTemplate="%s | Equipe">
          <title>{`${name}`}</title>
          <meta name="description" content={`${description}`} />
        </Helmet>
      </header>
      <section className="Layout__body">
        <TeammateTemplate
          content={html}
          featuredimage={featuredimage}
          contentComponent={HTMLContent}
          description={description}
          tags={tags}
          name={name}
          role={role}
          specialite={specialite}
          doctolib={doctolib}
        />
      </section>
    </Layout>
  );
};

TeammatePost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default TeammatePost;

export const pageQuery = graphql`
  query TeammatePostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        name
        role
        specialite
        description
        tags
        doctolib
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
